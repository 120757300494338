<template>
    <SearchScreen
        title="Tenders"
        api-destination="tenders"
        edit-key="tenderId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        add-new-button
        ref="search"
    >
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { booleanToYesNoForLocationFormatter } from '@/components/Common/Formatters.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'TenderFilters',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'tenderName',
                sortDesc: false,
                searchBy: 'All'
            },
            selectedLocationId: null,
            filterBy: ['Tender Name'],
            fields: [
                {
                    key: 'tenderName',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'isActiveOnline',
                    label: 'Enabled for Web (Selected Location)',
                    formatter: (value, key, item) => { return booleanToYesNoForLocationFormatter(item.activeOnlineByLocationDictionary, this.selectedLocationId);},
                    sortable: false
                },
                {
                    key: 'isActiveTender',
                    label: 'Active (Selected Location)',
                    formatter: (value, key, item) => { return booleanToYesNoForLocationFormatter(item.isDisabledByLocationDictionary, this.selectedLocationId);},
                    sortable: false
                }
            ]
        };
    },
    mounted: async function(){
        this.selectedLocationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
    },
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>