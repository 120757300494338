<template>
  <div>
    <b-modal
      v-bind="$attrs"
      v-on="$listeners"
      :name="name"
      :id="name"
      ref="modal"
      :ok-title="okLabel"
      >
      <template v-slot:modal-title>
        <h3>{{ label }}</h3>
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </b-modal>


    <!-- optional button to launch modal is included if a label is provided -->
    <b-button v-if="buttonLabel && smallButton"
                    :variant="buttonVariant"
                    size="sm"
                    class="py-0"
                    :is-busy='buttonBusy'
                    v-b-modal="name" :disabled="disableButton">{{ buttonLabel }}</b-button>
    <p-button v-else-if="buttonLabel" :is-busy='buttonBusy' :variant="buttonVariant" v-b-modal="name" :disabled="disableButton">
      <b-icon-plus v-if="iconPlus"></b-icon-plus> {{ buttonLabel }}
    </p-button>

  </div>
</template>

<script>
import baseInputs from "@/components/mixins/BaseInputs";

export default {
  name: "p-modal",
  mixins: [baseInputs],
  props: {
    buttonLabel: String,
    iconPlus:Boolean,
    okLabel: {
      type: String,
      default: "Ok"
    },
    disableButton: {
        type: Boolean,
        default: false
    },
    buttonBusy: {
        type: Boolean,
        default: false
    },
    smallButton: {
        type: Boolean,
        default: false
    },
    buttonVariant: {
        type: String,
        default: 'outline-primary'
    }
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide(){
      this.$bvModal.hide(this.name);
    }
  }
};
</script>

<style scoped>
/* Hide the horizontal lines below header and above footer */
>>> .modal-header {
  border-bottom: 0 none;
}
>>> .modal-footer {
  border-top: 0 none;
  z-index: 0;
}
>>> .modal-body{
min-height: 150px;
}
</style>
