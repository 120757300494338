<template>
    <div>
        <slot></slot>
        <b-row>
            <b-col v-if="purchaseOrder.generatedFromWantList">
                <span>
                    Generated from wantlist:
                    <b-link
                        @click="
                            loadWantList(
                                purchaseOrder.generatedFromWantList.wantListId
                            )
                        "
                        >{{ purchaseOrder.generatedFromWantList.name }}</b-link
                    >
                </span>
            </b-col>
            <b-col cols="12" class="d-flex">
                <ProductMultiSelectQuickSearch
                    is-active-filter
                    include-terms
                    label="Add Product(s)"
                    icon-plus
                    name="itemSelectTop"
                    @selections="addSelectedItem"
                    fieldSet="poFields"
                    :per-page="perPageAddProduct"
                    @per-page-change="e => {perPageAddProduct = e}"
                />
                <b-overlay :show='isExporting || isBusy'
                                rounded
                                opacity='0.5'
                                spinner-small
                                spinner-variant='primary'
                                class='d-inline-block ml-2 mr-2'>
                    <b-dropdown
                        text='Export'                        
                        variant="outline-primary"
                    >
                        <b-dropdown-item
                            @click='showExportPurchaseOrder()'
                            :disabled="statusClosed || isNew"
                            >Export PO (PDF)</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click='exportPurchaseOrder("csv")'
                            :disabled="statusClosed || isNew"
                            >Export PO (CSV)</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click='exportReceivingDocument("pdf")'
                            :disabled="!statusOpen || isNew"
                            >Export Receiving Document</b-dropdown-item
                        >
                    </b-dropdown>
                </b-overlay>
                <p-button
                    class="mb-2 mr-2"
                    variant="outline-primary"
                    @click="viewSupplierDiscounts"
                    v-b-modal.ViewSupplierDiscounts
                    :disabled="!purchaseOrder.supplierId"
                    :is-busy="isBusy"
                    >View Supplier Discounts</p-button
                >
                <p-button
                    class="mb-2"
                    variant="outline-danger"
                    @click="$emit('deletePurchaseOrder')"
                    :is-busy="isBusy"
                    >Delete PO</p-button
                >
              <p-button
                    variant="primary"
                    class="mb-2 ml-auto"
                    :is-busy="isBusy"
                    type="submit"
                    >Save</p-button
                >
            </b-col>
        </b-row>
        <p-form ref="purchaseOrderForm" id="purchaseOrder-form">
            <p-card>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-select
                            data-type="poStatus"
                            :value="purchaseOrder.status"
                            label="Status"
                            text-field="value"
                            rule="required"
                            @input="validateInvalidForm($event)"
                            :key="purchaseOrder.status + validationCheck"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="ponumber"
                            v-if="purchaseOrder.poNumber"
                            :value="purchaseOrder.poNumber"
                            label="PO Number"
                            disabled
                        />
                        <p-input
                            name="ponumber"
                            v-else
                            label="PO Number"
                            disabled
                            value="Generated On Save"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                            v-model="purchaseOrder.purchaseOrderDate"
                            label="PO Date"
                            :disabled="statusClosed || statusOpen"
                        />
                    </b-col>
                    <b-col>
                        <p-number
                            name="total"
                            input-type="money"
                            :value="total"
                            label="Total"
                            :disabled="true"
                            :add-money-rules="false"
                        />
                    </b-col>

                    <b-col>
                        <p-advanced-select
                            dataType="suppliers"
                            v-model="purchaseOrder.supplierId"
                            label="Supplier"
                            rules="required"
                            :disabled="statusClosed || statusOpen"
                            @change="supplierChanged(purchaseOrder.supplierId)"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                            v-model="purchaseOrder.expectedArrival"
                            label="Expected Arrival"
                            :disabled="statusClosed"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                            v-model="purchaseOrder.shipByDate"
                            label="Ship By"
                            :disabled="statusClosed"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                            v-model="purchaseOrder.cancelAfterDate"
                            label="Cancel After"
                            :disabled="statusClosed"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="Account Number"
                            label="Account Number"
                            v-model="purchaseOrder.supplier.accountNumber"
                            disabled
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            dataType="shippingProviders"
                            v-model="purchaseOrder.shippingProviderId"
                            label="Ship By Preference"
                            :disabled="statusClosed"
                            add-empty-option
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            dataType="shippingLevels"
                            v-model="purchaseOrder.shippingLevelId"
                            label="Ship By Service Level"
                            :disabled="statusClosed"
                            add-empty-option
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="trackingNumber"
                            v-model="purchaseOrder.trackingNumber"
                            label="Tracking Number"
                            rules="max:50"
                            :disabled="!statusOpen"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="Phone Number"
                            label="Phone Number"
                            v-model="purchaseOrder.supplier.phoneNumber"
                            disabled
                        />
                    </b-col>
                    <b-col>
                        <p-advanced-select
                            name="buyerName"
                            data-type="users"
                            v-model="purchaseOrder.buyerId"
                            label="Buyer"
                            :disabled="statusClosed || statusOpen"
                        />
                    </b-col>
                    <b-col>
                        <p-number
                            v-model="purchaseOrder.discountAmount"
                            input-type="money"
                            rules="min_value:0"
                            label="Discount Amount"
                            :disabled="statusClosed || statusOpen"
                            @blur="
                                amountDollarCheck(purchaseOrder.discountAmount)
                            "
                        />
                    </b-col>
                    <b-col>
                        <p-number
                            v-model="purchaseOrder.discountPercent"
                            input-type="percent"
                            rules="p-decimal:5,2|min_value:0|max_value:100"
                            label="Discount Percentage"
                            :disabled="statusClosed || statusOpen"
                            @blur="
                                amountDiscountCheck(
                                    purchaseOrder.discountPercent
                                )
                            "
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="Fax Number"
                            label="Fax Number"
                            v-model="purchaseOrder.supplier.faxNumber"
                            disabled
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            name="referencenumber"
                            v-model="purchaseOrder.referenceNumber"
                            label="Reference Number"
                            rules="max:50"
                            :disabled="statusClosed"
                        />
                    </b-col>
                    <b-col>
                        <p-address-input
                            same-as-address-name="Delivery"
                            label="Store Billing Address"
                            :key="purchaseOrder.purchaseOrderId"
                            v-model="purchaseOrder.storeBillingAddress"
                            :same-as-address="
                                purchaseOrder.storeShippingAddress
                            "
                            :disabled="statusClosed || statusOpen"
                        />
                    </b-col>
                    <b-col>
                        <p-address-input
                            :key="purchaseOrder.purchaseOrderId"
                            label="Store Delivery Address"
                            v-model="purchaseOrder.storeShippingAddress"
                            :disabled="statusClosed || statusOpen"
                        />
                    </b-col>

                </b-row>
                <b-row>
                    <b-col cols="12" lg="6">
                        <p-input
                            name="Email Address"
                            v-model="purchaseOrder.supplier.email"
                            label="Email Address"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" lg="3">
                        <label class="d-block">
                            Website Address:
                        </label>
                            <b-link
                                :href="'//'+purchaseOrder.supplier.website"
                                target="_blank"
                                >{{purchaseOrder.supplier.website}}</b-link
                            >

                    </b-col>
                </b-row>
                <b-row cols="1">
                    <p-text-area
                            class='px-3'
                            label='Comments'
                            rules='max:2000'
                            v-model="purchaseOrder.comment" />
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col cols="12" class="text-left">
                        <p-form inline v-if="!statusClosed">
                            <ProductMultiSelectQuickSearch
                                is-active-filter
                                include-terms
                                label="Add Product(s)"
                                icon-plus
                                name="itemSelect"
                                @selections="addSelectedItem"
                                fieldSet="poFields"
                                :per-page="perPageAddProduct"
                                @per-page-change="e => {perPageAddProduct = e}"
                            />
                            <b-button
                                variant="outline-primary"
                                v-if="!statusProposed"
                                class="mt-2"
                                @click="batchReceive()"
                                >Batch Receive</b-button
                            >
                            <b-button
                                variant="outline-primary"
                                class="ml-2 mt-2"
                                v-if="!statusProposed"
                                @click="batchCancel()"
                                >Batch Cancel</b-button
                            >
                        </p-form>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="12">
                        <b-overlay :show="!afterLoad"
                                    rounded
                                    opacity='0.5'
                                    spinner-variant='primary'
                                    >
                            <p-table
                                :items="purchaseOrder.purchaseOrderDetails"
                                :fields="getFields"
                                :current-page="currentPage"
                                :sort-by= filters.sortBy
                                :sort-desc=filters.sortDesc
                                :enable-row-selection="statusProposed"
                                :selection-actions="[{
                                    label: 'Delete',
                                    variant: 'outline-danger',
                                    action: this.removeProducts,
                                    hidden: !statusProposed
                                }]"
                                 :per-page="perPage"
                                v-show='afterLoad'
                                @sort-by-change="sortByUpdate"
                                @sort-desc-change="sortDescUpdate"
                                >
                                    <template
                                        v-if="!statusClosed"
                                        v-slot:head(cost)="data"
                                    >
                                        <b-radio
                                            :value="0"
                                            v-model="calculationOption"
                                            v-b-tooltip.hover.left
                                            title="Select To Calculate"
                                            >Cost</b-radio
                                        >
                                    </template>
                                    <template
                                        v-if="!statusClosed"
                                        v-slot:head(margin)="data"
                                    >
                                        <b-radio
                                            :value="1"
                                            v-model="calculationOption"
                                            v-b-tooltip.hover.left
                                            title="Select To Calculate"
                                            >Margin</b-radio
                                        >
                                    </template>
                                    <template
                                        v-if="!statusClosed"
                                        v-slot:head(retail)="data"
                                    >
                                        <b-radio
                                            :value="2"
                                            v-model="calculationOption"
                                            v-b-tooltip.hover.left
                                            title="Select To Calculate"
                                            description="test"
                                            >Retail</b-radio
                                        >
                                    </template>
                                    <template v-slot:cell(qty)="{ value, item }">
                                        <p-number
                                            v-model.number="item.qty"
                                            compact-format
                                            input-type="integer"
                                            name="Qty"
                                            rules="required|min_value:0"
                                            :disabled="statusClosed"
                                        />
                                    </template>

                                    <template v-slot:cell(rcv)="{ value, item }">
                                        <p-number
                                            v-model.number="item.receivedQty"
                                            compact-format
                                            input-type="integer"
                                            name="Rcv"
                                            rules="min_value:0"
                                            :disabled="statusClosed"
                                            @change="dissallowEmpty(item)"
                                        />
                                    </template>
                                    <template
                                        v-slot:cell(cancelled)="{ value, item }"
                                        v-if="!statusProposed"
                                    >
                                        <p-number
                                            v-model.number="item.cancelQty"
                                            compact-format
                                            input-type="integer"
                                            name="Cancelled"
                                            rules="min_value:0"
                                            :disabled="statusClosed"
                                            @change="dissallowEmpty(item)"
                                        />
                                    </template>
                                     <template
                                        v-slot:cell(backOrdered)="{ value, item }"
                                        v-if="!statusProposed"
                                    >
                                        <p-number
                                            v-model.number="item.backOrderQty"
                                            compact-format
                                            input-type="integer"
                                            name="Back Ordered"
                                            :rules="getBackorderRules(item)"
                                            :disabled="statusClosed"
                                            @change="dissallowEmpty(item)"
                                        />
                                    </template>
                                    <template v-slot:cell(margin)="{ value, item }">
                                        <p-number
                                            v-model="item.margin"
                                            compact-format
                                            @input="updateCalculation(item)"
                                            style="width: 80px;"
                                            name="Margin"
                                            input-type="percent"
                                            rules="required|max_value:99.99"
                                            :disabled="
                                                statusClosed || calculationOption === 1
                                            "
                                        />
                                    </template>
                                <template v-slot:cell(cost)="{ value, item }">
                                    <p-number
                                        v-model="item.cost"
                                        compact-format
                                        style="width: 80px;"
                                        @input="updateCalculation(item)"
                                        name="Cost"
                                        input-type="money"
                                        rules="required|min_value:0"
                                        :disabled="
                                            statusClosed || calculationOption === 0
                                        "
                                    />
                                </template>
                                <template v-slot:cell(retail)="{ value, item }">
                                    <p-number
                                        v-model="item.retail"
                                        compact-format
                                        @input="updateCalculation(item)"
                                        style="width: 80px;"
                                        name="Retail"
                                        input-type="money"
                                        rules="required|min_value:0"
                                        :disabled="
                                            statusClosed || calculationOption === 2
                                        "
                                    />
                                </template>
                                <template v-slot:cell(delete)="{ value, item }">
                                    <b-button
                                        v-if="!statusClosed"
                                        class="p-1 ml-1 short-button"
                                    >
                                        <BIconTrash
                                            icon="trash"
                                            @click="deleteDetail(item)"
                                        ></BIconTrash>
                                    </b-button>
                                </template>
                            </p-table>
                        </b-overlay>
                    </b-col>
                </b-row>
            </p-card>
        </p-form>
        <p-modal
            name="ViewSupplierDiscounts"
            label="Supplier Discounts"
            ref="ViewSupplierDiscounts"
            size="md"
            ok-only
            ok-title="Close"
        >
            <template v-slot:default>
                <b-row>
                    <b-col>
                        <p-table
                            :items="SupplierDiscounts"
                            :fields="SupplierDiscountsFields"
                            :enableRowSelection="false"
                        />
                    </b-col>
                </b-row>
            </template>
        </p-modal>
        <p-modal
            @ok="exportPurchaseOrder('pdf')"
            size="sm"
            button-label="Export PO (PDF)"
            :disable-button="statusClosed || isNew"
            label="Export PO"
            ref="exportPurchaseOrderModal"
            name="exportPurchaseOrderModal"
        >
            <template v-slot:default>
                <p-checkbox label="Include Retail" v-model="includeRetail" />
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import {
    currencyFormatter,
    bookVariantFormatter
} from '../../components/Common/Formatters.js';
import unionBy from 'lodash/unionBy';
import pricing from '@/services/PricingCalculations.js';
import { BIconTrash } from 'bootstrap-vue';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    name: 'PurchaseOrder',
    props: {
        purchaseOrder: Object,
        isNew: {
            type: Boolean,
            value: false
        },
        isBusy:  {
            type: Boolean,
            value: false
        }
    },
    data() {
        return {
            currentPage: 1,
            calculationOption: 1,
            validationCheck: 0,
            perPage: 500,
            perPageAddProduct: 100,
            afterLoad: false,
            isExporting: false,
            includeRetail: true,
            filters: {
                sortBy: 'authorTitleOrDescription',
                sortDesc: true,
            },
            // details grid
             fields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Author /Title or Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                },
                {
                    key: 'copyright',
                    sortable: true,
                    label: 'Variant 1 /Copyright'
                },
                {
                    key: 'bookVariant',
                    formatter: bookVariantFormatter,
                    label: 'Type'
                },
                {
                    key: 'edition',
                    sortable: true,
                    label: 'Variant 2 /Edition'
                },
                {
                    key: 'isbnCatalog',
                    sortable: true,
                    label: 'Catalog # /ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'catalogNumber',
                    label: 'Catalog #',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'cost',
                    thClass: 'text-center'
                },
                {
                    key: 'margin',
                    thClass: 'text-center'
                },
                {
                    key: 'retail',
                    thClass: 'text-center'
                },
                {
                    key: 'unitsPerPackage',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    sortable: true,
                    label: 'Purchase Unit'
                },
                {
                    key: 'qty',
                    thClass: 'text-center'
                },
                {
                    key: 'requiredQty',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    label: 'Req Qty',
                    formatter: (value, key, item) => {
                        return item.qty * item.unitsPerPackage;
                    }
                },
                {
                    key: 'total',
                    formatter: (value, key, item) => {
                        return currencyFormatter(item.qty * item.cost);
                    },
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'delete',
                    label: ''
                },
                {
                    key: 'barcodes',
                    label: 'Barcode',
                    hidden: true,
                    automaticSearch: true
                }
            ],
            SupplierDiscountsFields: [
                {
                    key: 'discountCode',
                    label: 'Code'
                },
                {
                    key: 'lowQty',
                    label: 'Low',
                    formatter: (value, key, item) => {
                        if (item.type == 'Dollar') {
                            return currencyFormatter(item.lowQty);
                        } else {
                            return item.lowQty;
                        }
                    }
                },
                {
                    key: 'highQty',
                    label: 'High',
                    formatter: (value, key, item) => {
                        if (item.type == 'Dollar') {
                            return currencyFormatter(item.highQty);
                        } else {
                            return item.highQty;
                        }
                    }
                },
                {
                    key: 'discount',
                    label: 'Disc.%'
                },
                {
                    key: 'type',
                    label: 'Type'
                },
                {
                    key: 'poQuantity',
                    label: 'PO Qty'
                },
                {
                    key: 'poDiscount',
                    label: 'PO Disc.',
                    formatter: currencyFormatter
                }
            ],
            SupplierDiscounts: []
        };
    },
    computed: {
        totalRows() {
            return this.purchaseOrder != null &&
                this.purchaseOrder.purchaseOrderDetails != null
                ? this.purchaseOrder.purchaseOrderDetails.length
                : 0;
        },

        total() {
            let total = this.totalWithDiscount(
                this.purchaseOrder != null &&
                    this.purchaseOrder.purchaseOrderDetails != null &&
                    this.purchaseOrder.purchaseOrderDetails
                        .map(d => d.qty * d.cost)
                        .reduce((a, b) => a + b, 0)
            );
            this.setTotal(total);
            return total;
        },
        getFields() {
            if (this.statusProposed) {
                return this.fields;
            }
            let addFields = [...this.fields];
            let idx = 0;

            //I was unable to make indexof work on addRcv array.
            addFields.forEach((x, index) => {
                if (x.key === 'qty') {
                    idx = index;
                }
            });
            addFields.splice(idx + 1, 0, { key: 'rcv', sortable: false });
            addFields.splice(idx + 2, 0, { key: 'cancelled', sortable: false });
            addFields.splice(idx + 3, 0, { key: 'backOrdered', label: 'Back Ordered', sortable: false });
            addFields.splice(idx + 4, 0, {
                key: 'outQty',
                label: 'Out Qty',
                sortable: true,
                sortByFormatted: true,
                thClass: 'text-center',
                tdClass: 'text-right',
                formatter: (value, key, item) =>
                { return (item.qty - item.receivedQty - item.cancelQty) * item.unitsPerPackage} });

            return addFields;
        },
        statusClosed() {
            return this.purchaseOrder.status === 'Closed';
        },
        statusOpen() {
            return this.purchaseOrder.status === 'Open';
        },
        statusProposed() {
            return this.purchaseOrder.status === 'Proposed';
        }
    },
    methods: {
        viewSupplierDiscounts() {
            axios
                .post('suppliers/getsupplierdiscounts', {
                    SupplierId: this.purchaseOrder.supplierId,
                    PurchaseOrderId: this.purchaseOrder.purchaseOrderId,
                    ProductDetails: this.purchaseOrder.purchaseOrderDetails
                })
                .then(resp => {
                    this.SupplierDiscounts = resp.data;
                });
        },
        getBackorderRules(product)
        {
            return `min_value:0|exceedQty:${product.qty * product.unitsPerPackage},${product.backOrderQty}`
        },
        loadWantList(wantListId) {
            this.$router.push(`/wantlists/${wantListId}`);
        },
        totalWithDiscount(value) {
            let dollarValue = value;
            if (
                this.purchaseOrder.discountAmount != null &&
                this.purchaseOrder.discountAmount !== 0
            ) {
                dollarValue = value - this.purchaseOrder.discountAmount;
            } else if (
                this.purchaseOrder.discountPercent != null &&
                this.purchaseOrder.discountPercent !== 0
            ) {
                dollarValue =
                    value - value * (this.purchaseOrder.discountPercent / 100);
            }

            value = dollarValue < 0 ? 0 : dollarValue;

            return value;
        },
        amountDollarCheck(item) {
            if (item === null) {
                this.purchaseOrder.discountAmount = 0;
            }
        },
        amountDiscountCheck(item) {
            if (item === null) {
                this.purchaseOrder.discountPercent = 0;
            }
        },
        dissallowEmpty(item) {
            if (!item.cancelQty) {
                item.cancelQty = 0;
            }
            if (!item.receivedQty) {
                item.receivedQty = 0;
            }
            if(!item.backOrderQty){
                item.backOrderQty = 0;
            }
        },
        setTotal(total) {
            this.purchaseOrder.total = total;
        },
        addSelectedItem(items) {
            // now I have questions about how we deal with locations...
            // I'd also really like to extract this logic to a class...
            let details = items.map(i => ({
                qty: 0,
                receivedQty: 0,
                cancelQty: 0,
                backOrderQty: 0,
                detailSupplierId:i.supplierId,
                purchaseOrderId: this.purchaseOrder.purchaseOrderId,
                ...i
            }));

            this.purchaseOrder.purchaseOrderDetails = unionBy(
                this.purchaseOrder.purchaseOrderDetails,
                details,
                x => x.sku + '-' + (x.termId ?? 0)
            );

            this.purchaseOrder.purchaseOrderDetails.forEach(i => {
                i.margin = pricing.computeMargin(i.cost, i.retail)
            });
        },
        deleteDetail(detail) {
            let hasInvoiceDetail = false;
            this.purchaseOrder.invoices.forEach(x => {
                if (
                    x.invoiceDetails.some(
                        p => p.sku === detail.sku && p.termId === detail.termId
                    )
                ) {
                    hasInvoiceDetail = true;
                }
            });
            if (hasInvoiceDetail) {
                this.$toasted.global
                    .app_error(
                        'This product cannot be deleted because it has invoices/returns attached to it.'
                    )
                    .goAway(5000);
                return;
            } else {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to remove this product from the purchase order?'
                    )
                    .then(value => {
                        if (value) {
                            let index = this.purchaseOrder.purchaseOrderDetails.indexOf(
                                detail
                            );
                            if (index !== -1) {
                                this.purchaseOrder.purchaseOrderDetails.splice(
                                    index,
                                    1
                                );
                            }
                        }
                    });
            }
        },
        removeProducts(products){
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to remove the selected product(s) from the purchase order?')
                .then((value) => {
                    if (value) {
                        products.forEach((x) => {
                            let index = this.purchaseOrder.purchaseOrderDetails.indexOf(x);
                            if (index !== -1) {
                                this.purchaseOrder.purchaseOrderDetails.splice(index, 1);
                            }
                        });
                    }
                });
        },
        updateCalculation: function(item) {
            switch (this.calculationOption) {
                case 0:
                    item.cost = pricing.computeCost(item.retail, item.margin);
                    break;
                case 1:
                    item.margin = pricing.computeMargin(item.cost, item.retail);
                    break;
                case 2:
                    item.retail = pricing.computeRetail(
                        item.cost,
                        item.margin,
                        item.retail,
                        this.parameters.roundingTarget
                    );
                    break;
            }
        },
        async validateInvalidForm(value) {
            if (!(await this.$refs.purchaseOrderForm.validate())) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Please resolve all validation errors on this Purchase Order before changing status.'
                    )
                    .goAway(5000);
                return;
            } else if (value === 'Closed' && this.purchaseOrder.purchaseOrderDetails.some(x => x.backOrderQty > 0)) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Status cannot be changed to \'Closed\' because there are back ordered products.'
                    )
                    .goAway(5000);
                return;
            } else if (
                value === 'Closed' &&
                this.purchaseOrder.purchaseOrderDetails.some(
                    x => x.receivedQty + x.cancelQty < x.qty
                )
            ) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'All products must be received or cancelled before closing this Purchase Order.'
                    )
                    .goAway(5000);
                return;
            } else if (
                this.purchaseOrder.status === 'Closed' &&
                value === 'Proposed'
            ) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error('Status cannot be changed \'Closed\' to Proposed.')
                    .goAway(5000);
                return;
            } else if (
                this.purchaseOrder.status === 'Open' &&
                value === 'Proposed' &&
                this.purchaseOrder.invoices.length > 0
            ) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Status cannot be changed to \'Proposed\' because they have at invoice(s)/return(s).'
                    )
                    .goAway(5000);
                return;
            } else {
                this.purchaseOrder.status = value;
            }
        },
        showExportPurchaseOrder() {
            this.$bvModal.show('exportPurchaseOrderModal');
        },
        async exportPurchaseOrder(exportFormat) {
            let apiUrl = '/purchaseorders/printpurchaseorders';

            this.isExporting = true;
            let selectedid = [this.purchaseOrder.purchaseOrderId];
            axios
                .post(
                    apiUrl,
                    {
                        ids: selectedid,
                        includeRetail: this.includeRetail,
                        exportFormat: exportFormat,
                        sortBy: this.filters.sortBy,
                        sortDesc: this.filters.sortDesc
                    },
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        exportFormat == 'pdf' ? `Purchase Order - ${this.purchaseOrder.poNumber}` : `Purchase Order - ${this.purchaseOrder.poNumber}.csv`,
                        exportFormat == 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Purchase Order report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isExporting = false;
                });
        },
        async exportReceivingDocument(exportFormat) {
            let apiUrl = '/purchaseorders/exportpdfreceivingdocument';

            this.isExporting = true;
            let selectedid = [this.purchaseOrder.purchaseOrderId];
            axios
                .post(
                    apiUrl,
                    {
                        ids: selectedid,
                        exportFormat: exportFormat
                    },
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        exportFormat == 'pdf' ? `Receiving Document - ${this.purchaseOrder.poNumber}` : `Receiving Document - ${this.purchaseOrder.poNumber}.csv`,
                        exportFormat == 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Receiving Document report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isExporting = false;
                });
        },
        batchReceive() {
            this.purchaseOrder.purchaseOrderDetails.forEach(x => {
                x.receivedQty = (x.qty - x.cancelQty - x.backOrderQty);
            });
            this.closePurchaseOrder();
        },
        batchCancel() {
            this.purchaseOrder.purchaseOrderDetails.forEach(x => {
                x.cancelQty = (x.qty - x.receivedQty - x.backOrderQty);
            });
            this.closePurchaseOrder();
        },
        closePurchaseOrder() {
            if(!this.purchaseOrder.purchaseOrderDetails.some(x => x.backOrderQty > 0))
            {
                this.purchaseOrder.status = 'Closed';
            }
        },
        async supplierChanged(supplierId)
        {
            if (!supplierId) return;
            let selectedLocationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            axios
                .get('suppliers', { params: { id: supplierId, locationId: selectedLocationId } })
                .then(resp => {
                    if (resp.data != '') {
                        let supplier = resp.data;
                        this.purchaseOrder.shippingLevelId = supplier.supplierLocation.shippingLevelId;
                        this.purchaseOrder.shippingProviderId = supplier.supplierLocation.shippingProviderId;
                    }
                });
        },
        sortByUpdate(value) {
            this.filters.sortBy = value;
        },
        sortDescUpdate(value) {
            this.filters.sortDesc = value;
        },
    },
    mounted: function() {
        axios.get('products/gmparameters').then(resp => {
            this.parameters = resp.data;
        });
        setTimeout(() => {
            this.afterLoad = true;
        }, 300);
    },
    components: {
        ProductMultiSelectQuickSearch,
        BIconTrash
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}
/deep/ .form-control.is-invalid{
    border-color:#f05050 !important;
}
</style>
