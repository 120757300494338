<template>
    <div class="container-fluid content-wrapper">
        <div class="content-heading">Stock Adjustment Report</div>
        <p-form ref="form">
            <p-card>
                <b-overlay :show="preparingDownload">
                    <template v-slot:overlay>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong> Preparing Download...</strong>
                        </div>
                    </template>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <p-input
                                label="Search"
                                ref='searchRef'
                                :placeholder="searchMessageComputed"
                                v-model="filters.automatic"
                                type="search"
                            />
                        </b-col>
                        <b-col cols="12" lg="2">
                            <p-select
                                style="margin-top:28px"
                                v-model="filters.searchBy"
                                :options="filters.options"
                            />
                        </b-col>
                         <b-col cols="12" md="6" lg="2">
                            <p-datepicker
                                label="Start Date"
                                v-model="filters.startDate"
                                vid="reportStartDate"
                            />
                        </b-col>
                        <b-col cols="12" md="6" lg="2">
                            <p-datepicker
                                label="End Date"
                                vid="endDate"
                                rules="dateGreaterOrEqual:@reportStartDate"
                                v-model="filters.endDate"
                                is-end-date
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button
                                variant="outline-primary"
                                @click="clickPreview"
                                class="mr-2"
                                >Preview</b-button
                            >
                            <b-button variant="primary" @click="downloadFile"
                                >Export</b-button
                            >
                        </b-col>
                    </b-row>
                </b-overlay>
                <b-row class="mt-3">
                    <b-col v-if="csv && csv !== null">
                        <p-search-table
                            :include-search-input="false"
                            api-destination="stockadjustment"
                            :filters="filters"
                            :fields="fields"
                            :enable-row-selection="false"
                            :show-per-page-selector="false"
                            :show-top-paging="false"
                            ref="stockAdjustmentTable"
                            :sort-by=filters.sortBy
                            @filtered="preview"
                            @sort-by-change="sortByUpdate"
                            @sort-desc-change="sortDescUpdate"
                            hideSearch
                        >
                        </p-search-table>
                    </b-col>
                </b-row>
            </p-card>
            <p-card v-if="csv !== null">
                <b-row>
                    <b-col>
                        <p-text-area
                            rows="15"
                            class="report-textarea"
                            v-model="csv"
                            label="CSV Preview (top 20 lines)"
                        />
                    </b-col>
                </b-row>
            </p-card>
        </p-form>
    </div>
</template>

<script>
import axios from 'axios';
import {
    currencyFormatter,
    datetimeToLocalTimeFormatter,
    searchPlaceHolderFormatter
} from '../../components/Common/Formatters.js';

import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
export default {
    data() {
        return {
            csv: null,
            preparingDownload: false,
            filters: {
                startDate: null,
                endDate: null,
                automatic: "",
                searchBy: 'All',
                options: ['All', 'User Name', 'SKU', 'Author', 'Title', 'Description', 'Catalog #', 'ISBN', 'Barcode'],
                locationId: null,
                sortBy: 'adjustmentDate',
                sortDesc: false,
            },
            fields: [
                {
                    key: 'defaultBarcode',
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right',
                },
                {
                    key: 'sku',
                    label: 'SKU'
                },
                {
                    key: 'dcc',
                    label: 'DCC'
                },
                {
                    key: 'description',
                    thClass: 'text-center',
                    tdClass: 'text-left',
                },
                {
                    key: 'variant1Copyright',
                    label: 'Variant 1 / Copyright'
                },
                {
                    key: 'variant2Edition',
                    label: 'Variant 2 / Edition'
                },
                {
                    key: 'qty',
                    sortable: true,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'extendedRetail',
                    label: 'Ext Retail',
                    sortable: true,
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: currencyFormatter
                },
                {
                    key: 'extendedCost',
                    label: 'Ext Cost',
                    sortable: true,
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: currencyFormatter
                },
                {
                    key: 'reason'
                },
                {
                    key: 'reference',
                    tdClass: 'noWrap'
                },
                {
                    key: 'comment'
                },
                {
                    key: 'locationName',
                    label: 'Location'
                },
                {
                    key: 'userName'
                },
                {
                    key: 'adjustmentDate',
                    label: 'Adjustment Date',
                    formatter: datetimeToLocalTimeFormatter
                }
            ]
        };
    },
    mounted : async function() {
        this.$refs.searchRef.$refs.input.select(); 
        this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
    },
    computed: {
        searchMessageComputed() {
            return searchPlaceHolderFormatter(this.filters.options,this.filters.searchBy);
        }
    },
    methods: {
        clickPreview() {
            this.preview();
            if (this.$refs.stockAdjustmentTable) {
                this.$refs.stockAdjustmentTable.refreshSearchResults();
            }
        },
        async preview() {
            if (this.$refs.form.isFieldInvalid('endDate')) {
                return;
            }
            axios
                .get('stockadjustment/csv', {
                    params: { isPreview: true, ...this.filters }
                })
                .then(result => {
                    this.csv = result.data;
                });
        },
        async downloadFile() {
            if (this.$refs.form.isFieldInvalid('endDate')) {
                return;
            }
            this.preparingDownload = true;
            axios
                .get('stockadjustment/csv', {
                    params: this.filters,
                    responseType: 'blob'
                })
                .then(result => {
                    
                    let fileName = `StockAdjustment.csv`;
                    downloadFileToBrowser(
                        result.data,
                        fileName,
                        'application/csv'
                    );
                    this.preparingDownload = false;
                });
        },
        sortByUpdate(value) {
            this.filters.sortBy = value;
        },
        sortDescUpdate(value) {
            this.filters.sortDesc = value;
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}
</style>
