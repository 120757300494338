<template>
    <div>
        <SearchScreen
            title="Tenant Management"
            api-destination="tenants"
            edit-key="tenantId"
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            :add-new-button="this.$store.getters.currentUserHasRole('Developer')"
        >
        </SearchScreen>
    </div>
</template>

<script>
import SearchScreen from '../../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    components: { SearchScreen },
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'TenantFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'abbreviation',
                    sortable: true
                },
                {
                    key: 'subdomain',
                    sortable: true
                },
                {
                    key: 'provisionStatus',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true,
                    disabled: true
                }
            ]
        };
    },
    methods: {

    }
};
</script>

<style></style>
