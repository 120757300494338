<template>
    <SearchScreen
        title="Invoices"
        api-destination="invoices"
        edit-key="invoiceId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :use-bootstrap-selection="false"
        add-new-button
        ref="search"
        @selected="onSelection"
        @selected-rows-changed="selectedRowsChanged"        
        submit-search-as-post
    >
        <template v-slot:actionButtons>
			<router-link to="/invoices/newexp" class="btn btn-secondary ml-2">
				<b-icon-plus></b-icon-plus>Misc Expenses
			</router-link>
            <b-dropdown
                text="Export"
                class="ml-2"
                :is-busy="isBusy"> 
                <b-dropdown-item
                    @click="exportInvoiceSummary('csv')"
                    :disabled="selectedInvoices.length <= 0"
                    >Invoice Summary (CSV)</b-dropdown-item>
                <b-dropdown-item
                    @click="exportInvoiceSummary('html')"
                    :disabled="selectedInvoices.length <= 0"
                    >Invoice Summary (View/Print)</b-dropdown-item>
                <b-dropdown-item @click="exportInvoiceDetail('csv')" :disabled="selectedInvoices.length <= 0">
                    Invoice Detail (CSV)</b-dropdown-item>
                <b-dropdown-item @click="exportInvoiceDetail('html')" :disabled="selectedInvoices.length <= 0">
                    Invoice Detail (View/Print)</b-dropdown-item>
                <b-dropdown-item @click="exportAgingByInvoice('csv')" :disabled="selectedInvoices.length <= 0">
                    Invoice Aging by Invoice (CSV)</b-dropdown-item>
                <b-dropdown-item @click="exportAgingByInvoice('html')" :disabled="selectedInvoices.length <= 0">
                    Invoice Aging by Invoice (View/Print)</b-dropdown-item>                
                <b-dropdown-item @click="exportAgingBySupplier('csv')">
                    Invoice Aging by Supplier (CSV)</b-dropdown-item>
                <b-dropdown-item @click="exportAgingBySupplier('html')">
                    Invoice Aging by Supplier (View/Print)</b-dropdown-item>
            </b-dropdown>
        </template>
		<template v-slot:belowSearch>
			<p-form>
				<b-row class="ml-3 mt-4 mr-2">
					<p-checkbox label="Open" class="ml-2" v-model="filters.open" />
					<p-checkbox label="Posted" class="ml-3" v-model="filters.posted" />
					<p-checkbox label="Cancelled" class="ml-3" v-model="filters.cancelled" />
					<p-checkbox label="Paid" class="ml-3" v-model="filters.paid" />
					<b-col md="3" class="ml-md-auto">
						<p-datepicker label="Invoice Date: From" vid="invoiceBeginDate" v-model="filters.beginDate" />
					</b-col>
					<b-col md="3">
						<p-datepicker label="Invoice Date: To" v-model="filters.endDate"
							rules="dateGreaterOrEqual:@invoiceBeginDate" is-end-date />
					</b-col>
				</b-row>
			</p-form>
		</template>
	</SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { currencyFormatter, dateFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            isBusy: false,
            selectedInvoices: [],
            filterName: 'InvoicesFilter',
			refreshSearchResults: false,
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'invoiceDate',
                sortDesc: true,
                beginDate: null,
                endDate: null,
                open: true,
                posted: false,
                cancelled: false,
                paid: false,
                searchBy: 'All',
                selectedIds: null
            },
            filterExclude: [
                'selectedIds' // Do not save selectedIds in local storage
            ],            
            filterBy: ['All', 'Invoice #', 'ISBN', 'Barcode', 'Catalog #', 'Supplier', 'PO #', 'SKU'],
            fields: [
                {
                    key: 'invoiceNumber',
                    sortable: true
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                {
                    key: 'invoiceDate',
                    formatter: dateFormatter,
                    sortable: true
                },
                {
                    key: 'total',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: currencyFormatter,
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true
                }
            ]
        };
    },
    methods: {
        onSelection(invoices) {
            this.selectedInvoices = invoices;
        },
        selectedRowsChanged(selectedInvoices) {
            this.onSelection(selectedInvoices);
        },
        async exportInvoiceDetail(format) {
            this.isBusy = true;

            this.filters.selectedIds = this.selectedInvoices?.map(invoice => invoice.invoiceId);
            if (format == 'csv') {
                let apiUrl = '/invoices/exportinvoicedetail';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                axios.post(
                    apiUrl,
                    {
                        searchFilter: this.filters,
                        exportFilter: {
                            locationId: this.filters.locationId,
                            exportFormat: format
                        }
                    },
                    { 
                        responseType: 'blob' 
                    })
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            'InvoiceDetail.csv',
                            'application/csv');

                        this.$toasted.global
                            .app_success('Invoice Detail CSV file generated successfully.')
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
            if (format == 'html') {
                let apiUrl='/invoices/exportinvoicedetail';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                this.isBusy = true;
                axios.post(
                    apiUrl,
                    {
                        searchFilter: this.filters,
                        exportFilter: {
                            locationId: this.filters.locationId,
                            exportFormat: format
                        }
                    },
                    { 
                        responseType: 'text/html'
                    }
                )
                .then(result => {
                    var htmlContent = result.data;
                    var newTab = window.open();
                    newTab.document.write(htmlContent);
                    newTab.document.title = 'Invoice Detail';                    
                    newTab.document.close();

                    this.$toasted.global
                        .app_success('Invoice Detail report generated successfully.')
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }            
        },	
        async exportAgingBySupplier(format) {
            this.isBusy = true;

            if (format == 'csv') {
                let apiUrl = '/invoices/exportinvoiceagingbysupplier';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                axios.post(
                    apiUrl,
                    {
                        locationId: this.filters.locationId,
                        exportFormat: format
                    },
                    { 
                        responseType: 'blob' 
                    })
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            'InvoiceAgingBySupplier.csv',
                            'application/csv');

                        this.$toasted.global
                            .app_success('Invoice Aging by Supplier CSV file generated successfully.')
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
            if (format == 'html') {
                let apiUrl='/invoices/exportinvoiceagingbysupplier';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                this.isBusy = true;
                axios.post(
                    apiUrl,
                    {
                        locationId: this.filters.locationId,
                        exportFormat: format
                    },
                    { 
                        responseType: 'text/html'
                    }
                )
                .then(result => {
                    var htmlContent = result.data;
                    var newTab = window.open();
                    newTab.document.write(htmlContent);
                    newTab.document.close();

                    this.$toasted.global
                        .app_success('Invoice Aging by Supplier report generated successfully.')
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }            
        },
        exportInvoiceSummary(format) {
            this.isBusy = true;
            this.filters.selectedIds = this.selectedInvoices?.map(invoice => invoice.invoiceId);
            switch(format) {
                case 'csv':
                    axios
                        .post('invoices/exportinvoicesummarycsv', this.filters, { responseType: 'blob' })
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Invoice Summary.csv', 'application/csv');
                            this.$toasted.global.app_success(`Invoice Summary generated successfully.`).goAway(5000);
                        })
                        .finally(() => {
                            this.finalizeExport();
                        });
                    break;
                case 'html':
                    axios
                        .post('invoices/exportinvoicesummaryhtml', this.filters, {responseType: 'text/html' })
                        .then((result) => {
                            var htmlContent = result.data;
                                var newTab = window.open();
                                newTab.document.write(htmlContent);
								newTab.document.title = 'Invoice Summary';
                                newTab.document.close();
                                this.$toasted.global.app_success(`Invoice Summary generated successfully.`).goAway(5000);
                        })
                        .finally(() => {
                            this.finalizeExport();
                        });
                    break;
            }
        },
        finalizeExport() {
            this.isBusy = false;
        },
        async exportAgingByInvoice(format) {
            this.isBusy = true;

            this.filters.selectedIds = this.selectedInvoices?.map(invoice => invoice.invoiceId);
            if (format == 'csv') {
                let apiUrl = '/invoices/exportinvoiceagingbyinvoice';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                axios.post(
                    apiUrl,
                    {
                        searchFilter: this.filters,
                        exportFilter: {
                        locationId: this.filters.locationId,
                        exportFormat: format
                        }
                    },
                    { 
                        responseType: 'blob' 
                    })
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            'InvoiceAgingByInvoice.csv',
                            'application/csv');

                        this.$toasted.global
                            .app_success('Invoice Aging by Invoice CSV file generated successfully.')
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
            if (format == 'html') {
                let apiUrl='/invoices/exportinvoiceagingbyinvoice';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                this.isBusy = true;
                axios.post(
                    apiUrl,
                    {
                        searchFilter: this.filters,
                        exportFilter: {
                        locationId: this.filters.locationId,
                        exportFormat: format
                        }
                    },
                    { 
                        responseType: 'text/html'
                    }
                )
                .then(result => {
                    var htmlContent = result.data;
                    var newTab = window.open();
                    newTab.document.write(htmlContent);
                    newTab.document.close();

                    this.$toasted.global
                        .app_success('Invoice Aging by Invoice report generated successfully.')
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }            
        }        	
    },
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
