<template>
    <div class="container-fluid content-wrapper">
        <div class="content-heading">Web Setup</div>
            <b-tabs justified>
                <b-tab  title="Email Templates">
                <EmailTamplates ref="emailTemplate"/>
                </b-tab>
                <b-tab title="Website Hosts" >
                <WebsiteHosts ref="webSiteHosts"/>
                </b-tab>
            </b-tabs>
    </div>
</template>

<script>
import EmailTamplates from './EmailConfiguration.vue';
import WebsiteHosts from './WebsiteConnections.vue';
export default {
  components: {
    EmailTamplates,
    WebsiteHosts
  },    
    async beforeRouteLeave(to, from, next) {
        //Because both tabs save independently of each other and this parent page
        //we are checking each navigation guards
        if(this.$refs?.emailTemplate.formIsDirty){
          await this.$refs?.emailTemplate.warnBeforeLeaving(next)
          return;
        }
        if(this.$refs?.webSiteHosts.formIsDirty){
          await this.$refs?.webSiteHosts.warnBeforeLeaving(next)
          return;
        }
                
        next();
    },
};
</script>